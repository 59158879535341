import { ReactElement, useEffect, memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import strings from "@locale/localization";
import Page from "../../components/Page/Page";
import { viewItemEventAction } from "app/tracking/trackingActions";
import { useMedia, useStructuredData } from "app/hooks";
import { LoginPage, SearchPage, SignupPage } from "@containers/routes";
import { RootState } from "app/reducers/rootReducer";
import useAuth from "app/auth/useAuth";
import { ActionWithAsyncResult, PodmeColor, Region, StructuredDataContent } from "@typings/index";
import { Helmet } from "react-helmet";
import config from "app/config";
import { isEmpty } from "lodash";
import { getLocale, uid } from "@utils/index";
import CmsSections from "@components/Cms/CmsSections";
import { ContentType } from "@containers/Homescreen/redux/types";
import { Box } from "@chakra-ui/react";
import {
    GetStartpageContent,
    GET_STARTPAGE_CONTENT,
    GET_STARTPAGE_CONTENT_FAILED,
    GET_STARTPAGE_CONTENT_SUCCESS,
} from "app/content/contentTypes";

const globalState = (state: RootState) => state.global;

function StartPage(): ReactElement {
    const dispatch = useDispatch();
    const { currency, packageId, price } = useSelector(globalState);
    const { discover, locale } = strings.routes;
    const { meta } = strings.startPage;
    const { supportEmail } = strings.global;
    const startPageContent = useSelector((state: RootState) => state.content.startPage);
    const { isAuthenticated } = useAuth();
    const { isSmallScreen, isMediumScreen } = useMedia();

    const structuredData = useStructuredData(StructuredDataContent.Organization, {
        url: locale,
        logoImgUrl: `${config.PUBLIC_URL}/assets/images/brand/podme-logo-white.svg`,
        supportEmail,
    });

    const structuredDataKey = useMemo(() => uid(), []);

    const startPageSections = useMemo(
        () =>
            (startPageContent?.sections &&
                startPageContent?.sections[0]?.layoutComponents?.map((section) => {
                    return {
                        content: section,
                    };
                })) ||
            [],
        [startPageContent]
    );

    useEffect(() => {
        LoginPage.preload();
        SignupPage.preload();
        SearchPage.preload();
        dispatch(viewItemEventAction("Premium offer startpage", String(packageId), currency, price));
    }, []);

    useMemo(() => {
        if (!startPageContent?.sections || startPageContent?.sections?.length === 0) {
            dispatch<ActionWithAsyncResult<GetStartpageContent>>({
                type: GET_STARTPAGE_CONTENT,
                region: getLocale(locale as Region),
                WAIT_FOR_ACTION: [GET_STARTPAGE_CONTENT_SUCCESS, GET_STARTPAGE_CONTENT_FAILED],
            });
        }
    }, [startPageContent, locale]);

    if (isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: `/${locale}/auth/${discover}`,
                }}
            />
        );
    }

    return (
        <>
            <Helmet>
                {!isEmpty(structuredData) && (
                    <script type="application/ld+json" key={structuredDataKey}>
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <Page
                background={PodmeColor.BlackPearl}
                meta={{
                    title: meta.title,
                    description: meta.description,
                    url: `/${locale}/`,
                    image: "https://podme.com/assets/images/brand/podme-premium-banner.png",
                }}
                columnSpan={isSmallScreen || isMediumScreen ? "1/-1" : undefined}
                collapseTop
            >
                <Box maxWidth="1440px" margin="0 auto">
                    <CmsSections
                        sections={startPageSections}
                        config={{
                            heroSectionProps: {
                                isFirstSection: startPageContent?.sections
                                    ? startPageContent?.sections[0]?.layoutComponents[0]?.type === ContentType.LandingHero
                                    : false,
                            },
                            valuePropositionSectionProps: {
                                margin: ["4rem 0", "2rem", "8rem 0"],
                            },
                            podcastCarouselProps: {
                                asTypeMain: true,
                                onHomescreen: true,
                            },
                        }}
                    />
                </Box>
            </Page>
        </>
    );
}

export default memo(StartPage);
